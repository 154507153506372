import React, { useState, useEffect, useCallback } from 'react';
import './css/theme.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import i18n from './i18n';

import PageMain from './components/PageMain';
import PageNotFound from './components/PageNotFound';
import PagePrivacy from './components/PagePrivacy';
import PageTerms from './components/PageTerms';
import PageCheckout from './components/PageCheckout';
import PageReturn from './components/PageReturn';
import HreflangTags from './components/HreflangTags';

function App() {
  const [checkoutValues, setCheckoutValues] = useState({});
  const [checkboxStates, setCheckboxStates] = useState({});
  const [userValues, setUserValues] = useState({});
  const [timeframe, setTimeframe] = useState("7");
  const [optionsNum, setOptionsNum] = useState(0);
  const [optionsVal, setOptionsVal] = useState("3");
  const supportedLanguages = ['en', 'zh-TW', 'zh', 'ko', 'ms', 'th', 'vi', 'es', 'fr', 'it'];
  const defaultLanguage = 'en'; // Set your default language here

  // Function to determine the language to use
  const getLanguage = () => {
    const browserLang = navigator.language.split('-')[0];
    if (supportedLanguages.includes(i18n.language)) {
      return i18n.language;
    } else if (supportedLanguages.includes(browserLang)) {
      return browserLang;
    } else {
      return defaultLanguage;
    }
  };

  const storeCheckoutValue = useCallback((valueName, value) => {
    setCheckoutValues(prevValues => ({
      ...prevValues,
      [valueName]: value
    }));
  }, []);

  const handleCheckboxChange = useCallback((name, isChecked) => {
    setCheckboxStates(prevStates => ({
      ...prevStates,
      [name]: isChecked
    }));
  }, []);

  const handleValueChange = useCallback((name, value) => {
    setUserValues(prevStates => ({
      ...prevStates,
      [name]: value
    }));
  }, []);

  const handleTimeframeChange = useCallback((value) => {
    setTimeframe(value);
  }, []);

  useEffect(() => {
    storeCheckoutValue("timeframe", timeframe);
  }, [timeframe, storeCheckoutValue])

  useEffect(() => {
    storeCheckoutValue("optionsNum", optionsNum);
  }, [optionsNum, storeCheckoutValue])

  useEffect(() => {
    const checkCount = Object.values(checkboxStates).filter(value => value).length;
    let newValue;
    if (checkCount >= 6) {
      newValue = 'X';
    } else if (checkCount >= 4) {
      newValue = '5';
    } else {
      newValue = '3';
    }
    let results = [];
    for (const [name, isChecked] of Object.entries(checkboxStates)) {
      if (isChecked) {
        const value = userValues[name];
        if (value) {

          if (value.includes(',')) {
            value.split(',').forEach(subValue => {
              results.push(`${name}=${subValue.trim()}`);
            });
          } else {

            results.push(`${name}=${value.trim()}`);
          }
        }
      }
    }
    const checkoutValues = results.join(';;')
    setOptionsNum(checkCount);
    setOptionsVal(newValue);
    storeCheckoutValue("checkoutValues", checkoutValues);
  }, [checkboxStates, userValues, storeCheckoutValue]);

  return (
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <Router>
          <HreflangTags />
          <Routes>
            {supportedLanguages.map((lang) => (
              <React.Fragment key={lang}>
                <Route path={`/${lang}`} element={<PageMain
                  checkboxStates={checkboxStates}
                  handleCheckboxChange={handleCheckboxChange}
                  userValues={userValues}
                  handleValueChange={handleValueChange}
                  timeframe={timeframe}
                  handleTimeframeChange={handleTimeframeChange}
                  optionsNum={optionsNum}
                  optionsVal={optionsVal}
                />} />
                <Route path={`/${lang}/checkout`} element={<PageCheckout inputValues={checkoutValues} />} />
                <Route path={`/${lang}/return`} element={<PageReturn />} />
                <Route path={`/${lang}/privacy`} element={<PagePrivacy />} />
                <Route path={`/${lang}/terms`} element={<PageTerms />} />
              </React.Fragment>
            ))}
            <Route path="/" element={<Navigate to={`/${getLanguage()}`} replace />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </HelmetProvider>
    </I18nextProvider>
  );
}

export default App;