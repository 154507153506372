import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useTranslation, Trans } from 'react-i18next';

function PagePrivacy() {
    const { t } = useTranslation();
    return (
        <div>
            <Header />
            <div className="flex flex-col items-center justify-center px-8 py-10 surface-variant on-surface-variant-text">
                <div className="rich-text-block prose-default max-width w-full">
                    <h1 className="text-heading-3 font-medium margin-6">{t('privacy.title')}</h1>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">{t('privacy.intro.update_label')}</b>
                        : {t('privacy.intro.update_text')}
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">{t('privacy.intro.content1')}</p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">
                            <u class="font-underline">{t('privacy.collected.title')}</u>
                        </b>
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">{t('privacy.collected.content1')}</p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">{t('privacy.collected.content2')}</p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">
                            <u class="font-underline">{t('privacy.used.title')}</u>
                        </b>
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">{t('privacy.used.content1')}</p>
                    <ul className="font-body">
                        <li>
                            <div className="font-serif margin-6 peer-margin-collapse">{t('privacy.used.purpose1')}</div>
                        </li>
                        <li>
                            <div className="font-serif margin-6 peer-margin-collapse">{t('privacy.used.purpose2')}</div>
                        </li>
                        <li>
                            <div className="font-serif margin-6 peer-margin-collapse">{t('privacy.used.purpose3')}</div>
                        </li>
                    </ul>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">
                            <u class="font-underline">{t('privacy.shared.title')}</u>
                        </b>
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">{t('privacy.shared.content1')}</p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">
                            <u class="font-underline">{t('privacy.security.title')}</u>
                        </b>
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">{t('privacy.security.content1')}</p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">
                            <u class="font-underline">{t('privacy.contact.title')}</u>
                        </b>
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <Trans i18nKey="return.email_us">
                            If you have any questions, comments, or concerns about our processing activities, please email us at <a href="mailto:contact@english-names.com" className="on-surface-variant-text custom-link">contact@english-names.com</a>.
                        </Trans>
                    </p>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default PagePrivacy