import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';

function Footer({ variant }) {
    const { t, i18n } = useTranslation();
    const getLangUrl = (path) => `/${i18n.language}${path}`;

    return (
        <>
            {variant ? (
                <div className="flex flex-col items-center md:items-start px-8 py-10 margin-to-bottom surface-variant on-surface-variant-text" >
                    <Link to={getLangUrl('/')}><Logo /></Link>
                    <a href="mailto:contact@english-names.com" className="on-surface-variant-text custom-link margin-4 font-serif">contact@english-names.com</a>
                    <div className="flex flex-col md:flex-row items-center md:items-start">
                        <span class="pr-0 md:pr-2 lg:pr-6 pb-2 md:pb-0 text-sm">© 2024 - My English Name</span>
                        <span class="text-sm">
                            <span>
                                <Link to={getLangUrl('/terms')} className="on-surface-variant-text custom-link margin-6 font-serif">{t('simple.Terms')}</Link>
                            </span>
                            <span> | </span>
                            <span>
                                <Link to={getLangUrl('/privacy')} className="on-surface-variant-text custom-link margin-6 font-serif">{t('simple.Privacy')}</Link>
                            </span>
                        </span>
                    </div>

                </div>

            ) : (
                <div className="flex flex-col items-center md:items-start px-8 py-10 margin-to-bottom surface on-surface-text" >
                    <Link to={getLangUrl('/')}><Logo /></Link>
                    <a href="mailto:contact@english-names.com" className="on-surface-text custom-link margin-4 font-serif">contact@english-names.com</a>
                    <div className="flex flex-col md:flex-row items-center md:items-start">
                        <span class="pr-0 md:pr-2 lg:pr-6 pb-2 md:pb-0 text-sm">© 2024 - My English Name</span>
                        <span class="text-sm">
                            <span>
                                <Link to={getLangUrl('/terms')} className="on-surface-variant-text custom-link margin-6 font-serif">{t('simple.Terms')}</Link>
                            </span>
                            <span> | </span>
                            <span>
                                <Link to={getLangUrl('/privacy')} className="on-surface-variant-text custom-link margin-6 font-serif">{t('simple.Privacy')}</Link>
                            </span>
                        </span>
                    </div>

                </div>
            )
            }
        </>
    )
}

export default Footer