import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const HreflangTags = () => {
  const location = useLocation();
  
  const supportedLanguages = ['en', 'zh-TW', 'zh', 'ko', 'ms', 'th', 'vi', 'es', 'fr', 'it'];
  
  const currentPath = location.pathname.replace(/^\/[^/]+/, '');
  const baseUrl = 'https://www.english-names.com';

  return (
    <Helmet>
      {supportedLanguages.map((lang) => (
        <link 
          key={lang} 
          rel="alternate" 
          hreflang={lang} 
          href={`${baseUrl}/${lang}${currentPath}`} 
        />
      ))}
      <link 
        rel="alternate" 
        hreflang="x-default" 
        href={`${baseUrl}/en${currentPath}`} 
      />
    </Helmet>
  );
};

export default HreflangTags;