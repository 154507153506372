import React from 'react';

function UserSelectionGrid({ children }) {
    return (
        <div className="user-selection-grid">
            {children}
        </div>
    );
}

export default UserSelectionGrid;
