import React, { } from 'react';
import '@material/web/button/filled-button.js';

function SimpleButton({ clickButton, text }) {

  return (
    <md-filled-button
      style={{
        width: '200px',
        fontWeight: '1000',
        margin: '12px',
      }}
      onClick={(event) => { clickButton() }}
    > {text} </md-filled-button>
  );
}

export default SimpleButton;

