import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import '@material/web/button/filled-button.js';
import '@material/web/menu/menu.js';
import '@material/web/menu/menu-item.js';
import '@material/web/textfield/outlined-text-field.js';
import '@material/web/icon/icon.js';


function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const menuRef = useRef(null);
    const rowRef = useRef(null);

    const LANGUAGE_MAP = {
        'en': 'English',
        'zh-TW': '繁體中文',
        'zh': '简体中文',
        'ko': '한국어',
        'ms': 'Bahasa Melayu',
        'th': 'ไทย',
        'vi': 'Tiếng Việt',
        'es': 'Español',
        'fr': 'Français',
        'it': 'Italiano'
      };
    /* Stripe accepts:
    * auto, bg, cs, da, de, el, en, en-GB, es, es-419, et, fi, fil, fr, fr-CA, hr, hu, id, it, ja, ko, lt, lv, ms, mt, nb, nl, pl, pt, pt-BR, ro, ru, sk, sl, sv, th, tr, vi, zh, zh-HK, or zh-TW
    */

    useEffect(() => {
        const anchorEl = document.body.querySelector(`#language-anchor`);
        const menuEl = menuRef.current;

        const handleClick = (event) => {
            event.stopPropagation();
            menuEl.open = !menuEl.open;
        };

        anchorEl.addEventListener('click', handleClick);

        return () => {
            anchorEl.removeEventListener('click', handleClick);
        };
    }, []);


    const changeLanguage = (language) => {
        const currentPathname = location.pathname;
        const languageCodes = Object.keys(LANGUAGE_MAP);
        const currentLanguageCode = languageCodes.find(code => currentPathname.startsWith(`/${code}`));
        
        let newPathname;
        if (currentLanguageCode) {
            newPathname = currentPathname.replace(`/${currentLanguageCode}`, `/${language}`);
        } else {
            newPathname = `/${language}${currentPathname}`;
        }
        
        i18n.changeLanguage(language);
        navigate(newPathname);
    };

    return (
        <div className="flex margin-2" ref={rowRef}>
            <span style={{ position: "relative" }}>
                <md-outlined-text-field
                    id={`language-anchor`}
                    value={LANGUAGE_MAP[i18n.language] || LANGUAGE_MAP[i18n.language.split('-')[0]] || 'Broken'}
                    readOnly="true"
                    hasTrailingIcon="true"
                    class="text-field-long"
                >
                    <md-icon slot="trailing-icon">expand_more</md-icon>
                </md-outlined-text-field>

                <md-menu class="background" ref={menuRef} anchor={`language-anchor`}>
                    {Object.entries(LANGUAGE_MAP).map(([key, value], index) => (
                        <md-menu-item
                            class="background"
                            key={key}
                            onClick={(event) => {
                                event.stopPropagation();
                                changeLanguage(key);
                            }}
                        >
                            <div slot="headline">{value}</div>
                        </md-menu-item>
                    ))}
                </md-menu>
            </span>
        </div>
    );
};

export default LanguageSwitcher