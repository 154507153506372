import React, { useEffect, useRef } from 'react';
import '@material/web/checkbox/checkbox.js';

function TrackedCheckbox({ name, checked, onCheckedChange }) {
  const checkboxRef = useRef(null);

  useEffect(() => {
    const checkbox = checkboxRef.current;
    if (checkbox) {
      const handleClick = event => {
        event.stopPropagation(); // Prevent click from bubbling up
      };
      const handleChange = () => {
        onCheckedChange(checkbox.checked);  // Notify parent component
      };

      checkbox.addEventListener('change', handleChange);
      checkbox.addEventListener('click', handleClick);
      
      return () => {
        checkbox.removeEventListener('change', handleChange);
        checkbox.removeEventListener('click', handleClick);
      };
    }
  }, [onCheckedChange]);

  useEffect(() => {
    const checkbox = checkboxRef.current;
    if (checkbox) {
      checkbox.checked = checked;
    }
  }, [checked]);

  return (
    <md-checkbox
      ref={checkboxRef}
      touch-target="wrapper"
      name={name}
      checked={checked}>
    </md-checkbox>
  );
}

export default TrackedCheckbox;

