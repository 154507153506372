import React, { useState, useRef, useEffect } from 'react';
import '@material/web/chips/filter-chip.js';
import '@material/web/chips/chip-set.js'
import TrackedCheckbox from './TrackedCheckbox';
import UserSelectionQualityChip from './UserSelectionQualityChip';
import { useTranslation } from 'react-i18next';

function canSelectQuality(quality, selectedStates) {
  if (selectedStates[quality]) {
    return true;
  }
  const trueCount = Object.values(selectedStates).filter(value => value).length;
  return trueCount < 3;
}

function UserSelectionQualityGrid({ checked, handleCheckboxChange, handleValueChange, inValues }) {
  const { t } = useTranslation();
  const name = "Qualities"
  const englishItems = ["classic", "comedic", "complex", "delicate", "formal", "informal",
    "mature", "modern", "natural", "refined", "serious", "simple",
    "strange", "strong", "upper class", "urban", "wholesome", "youthful"];
  const displayItems = [t('user_grid.category.Qualities.classic'), t('user_grid.category.Qualities.comedic'),
  t('user_grid.category.Qualities.complex'), t('user_grid.category.Qualities.delicate'), t('user_grid.category.Qualities.formal'),
  t('user_grid.category.Qualities.informal'), t('user_grid.category.Qualities.mature'), t('user_grid.category.Qualities.modern'),
  t('user_grid.category.Qualities.natural'), t('user_grid.category.Qualities.refined'), t('user_grid.category.Qualities.serious'),
  t('user_grid.category.Qualities.simple'), t('user_grid.category.Qualities.strange'), t('user_grid.category.Qualities.strong'),
  t('user_grid.category.Qualities.upper class'), t('user_grid.category.Qualities.urban'), t('user_grid.category.Qualities.wholesome'),
  t('user_grid.category.Qualities.youthful')]
  const rowRef = useRef(null);

  const [selectedStates, setSelectedStates] = useState(inValues.length > 0 ? inValues.split(',').reduce((obj, key) => {
    obj[key.trim()] = true;
    return obj;
  }, {}) : {});

  // Notify parent when checked state changes
  const handleCheckedChange = (isChecked) => {
    handleCheckboxChange(name, isChecked);
    const trueKeys = Object.entries(selectedStates)
      .filter(([key, value]) => value) // Filter entries where value is true
      .map(([key, value]) => key)      // Map to get the keys only
      .join(',');
    handleValueChange(name, trueKeys);
  };

  const handleRowClick = () => {
    const checkbox = rowRef.current.querySelector('md-checkbox');
    if (checkbox) {
      checkbox.click(); // Simulate a click on the checkbox
    }
  };

  const handleSelectedChange = (quality, isSelected) => {
    setSelectedStates(prevStates => ({
      ...prevStates,
      [quality]: isSelected
    }));
  };

  useEffect(() => {
    const trueKeys = Object.entries(selectedStates)
      .filter(([key, value]) => value) // Filter entries where value is true
      .map(([key, value]) => key)      // Map to get the keys only
      .join(',');
    handleValueChange(name, trueKeys);
  }, [selectedStates, handleValueChange]);

  return (
    <div className="item max-column flex-col">
      <div className="row" ref={rowRef} onClick={handleRowClick}>
        <TrackedCheckbox name={name} checked={checked} onCheckedChange={handleCheckedChange} />
        <span className="font-body font-serif">{t('user_grid.category.Qualities.label')}:</span>
      </div>
      <md-chip-set>
        <div className="chip-grid">
          {englishItems.map(quality => (
            <UserSelectionQualityChip
              key={quality}
              quality={quality}
              display={displayItems[englishItems.indexOf(quality)]}
              enabled={canSelectQuality(quality, selectedStates)}
              selected={selectedStates[quality]}
              onSelectedChange={(isSelected) => handleSelectedChange(quality, isSelected)} />
          ))}
        </div>
      </md-chip-set>
    </div>
  );
}

export default UserSelectionQualityGrid;

