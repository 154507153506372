import React, { useRef, useEffect } from 'react';
import '@material/web/checkbox/checkbox.js';
import '@material/web/slider/slider.js';
import TrackedCheckbox from './TrackedCheckbox';

function UserSelectionSlider({ label, name, checked, value, minLabel, maxLabel, handleCheckboxChange, handleValueChange }) {
  const rowRef = useRef(null);
  const sliderRef = useRef(null);

  // Notify parent when checked state changes
  const handleCheckedChange = (isChecked) => {
    handleCheckboxChange(name, isChecked);
    const slider = sliderRef.current;
    if(slider) {
      handleValueChange(name, String(slider.value));
    }
  };
  // Notify parent when slider value changes
  
  useEffect(() => {
    const slider =  sliderRef.current;
    if (slider) {
      const handleSliderChange = () => {
        handleValueChange(name, String(slider.value));
      };
      
      slider.addEventListener('change', handleSliderChange);
      return () => {
        slider.removeEventListener('change', handleSliderChange);
      };
    }
  }, [name, handleValueChange]);
  
  
  const handleRowClick = () => {
    const checkbox = rowRef.current.querySelector('md-checkbox');
    if (checkbox) {
      checkbox.click(); // Simulate a click on the checkbox
    }
  };
  
  
  return (
    <div className="item single-column flex-col">
      <div className="row" ref={rowRef} onClick={handleRowClick}>
        <TrackedCheckbox name={name} checked={checked} onCheckedChange={handleCheckedChange} /> 
        <span className="font-body font-serif">{label}:</span>
      </div>
      <div className="slider-container">
        <md-slider 
          key={name}
          name={name}
          ref={sliderRef}
          min="0" 
          max="100" 
          value={value}
          style={{ width: '270px', marginLeft: '30px'}}
        />
        <div className="slider-labels">
          <span className="text-sm font-serif">{minLabel}</span>
          <span className="text-sm font-serif">{maxLabel}</span>
        </div>
      </div>
    </div>
  );
}

export default UserSelectionSlider;

