import React, { useEffect, useState } from 'react';
import priceCosts from '../config/priceCosts';
import '@material/web/button/filled-button.js';
import SimpleButton from './SimpleButton'
import { useTranslation } from 'react-i18next';

function BuyButton({ timeframe, optionsNum, startCheckout}) {
  const { t } = useTranslation();

  const [priceCost, setPriceCost] = useState('');

  useEffect(() => {
    let optionsVal;  
    if (optionsNum >= 6) {
      optionsVal = 'X';
    } else if (optionsNum >= 4) {
      optionsVal = '5';
    } else {
      optionsVal = '3';
    }
    const costKey = `days${timeframe}choices${optionsVal}`;      
    setPriceCost( priceCosts[costKey] );

  }, [timeframe, optionsNum]);
      
  return (
    <div className="flex flex-col items-center card-outline surface-variant on-surface-variant-text">
      <span className="text-heading-3 font-medium margin-2">{t('pay.header')}</span>
      <span className="font-body font-serif margin-2">{t('pay.options', {count: optionsNum})}</span>
      <span className="font-body font-serif margin-2">{t(`pay.days${timeframe}`)}</span>
      <span className="text-heading-3 font-medium margin-2">{t('pay.cost', {amount: priceCost})}</span>
      <SimpleButton
        clickButton={startCheckout}
        text={t('simple.Pay')}
      />
    </div>
  );
}

export default BuyButton;
