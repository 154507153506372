import React, { useEffect, useRef } from 'react';

function SegmentedButton({
  name,
  segments,
  callback,
  selectedValue
}) {
  const controlRef = useRef();

  useEffect(() => {
    const activeSegment = segments.find(seg => seg.value === selectedValue);
    if (!activeSegment) return;

    const activeSegmentRef = activeSegment.ref;
    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;

    style.setProperty("--highlight-width", `${offsetWidth}px`);
    style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
  }, [selectedValue, segments]);

  return (
    <div className="segments-container" ref={controlRef}>
      <div className={"segments ready text-sm font-serif"}>
        {segments.map((item) => (
          <div
            key={item.value}
            className={`segment ${item.value === selectedValue ? "active" : "inactive"}`}
            ref={item.ref}
          >
            <input
              type="radio"
              value={item.value}
              id={item.label}
              name={name}
              onChange={() => callback(item.value)}
              checked={item.value === selectedValue}
            />
            <label htmlFor={item.label}>{item.label}</label>
            <label htmlFor={item.secondaryLabel}>{item.secondaryLabel}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SegmentedButton
