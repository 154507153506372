import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';
import LanguageSwitcher from './LanguageSwitcher';

function Header({ contentText }) {
    const { i18n } = useTranslation();

    // Function to prepend the current language to the URL
    const getLangUrl = (path) => `/${i18n.language}${path}`;

    return (
        <div className="flex flex-col items-center">
            <header className="flex flex-col flex-wrap md:flex-row justify-between items-center max-width md:w-full px-8 py-10 surface on-surface-text">
                <div className="header-item">
                    <Link to={getLangUrl('/')}>
                        <Logo />
                    </Link>
                </div>
                <div className="header-item">
                    <h1 className="text-heading-3 font-medium">{contentText}</h1>
                </div>
                <div className="header-item">
                    <LanguageSwitcher />
                </div>
            </header>
        </div>
    )
}

export default Header;