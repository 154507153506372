import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Footer from './Footer';

function PageNotFound() {
  const { t } = useTranslation();

  return (
    <div className="h-screen flex flex-col justify-center on-background-text">
      <Header/>
      <div className="rich-text-block prose-default max-width w-full centeredItem margin-6">
        <h2 className="text-heading-3 font-medium margin-2">{t('error.page_missing.header')}</h2>
        <h2 className="text-heading-4 font-medium margin-6 peer-margin-collapse">{t('error.page_missing.sub-head')}</h2>
      </div>
      <Footer variant={true}/>      
    </div>
  )
}

export default PageNotFound;
