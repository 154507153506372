import React from 'react';
import { useTranslation } from 'react-i18next';

function CheckoutValuesDisplay({ checkoutValues }) {
    const { t } = useTranslation();
    const checkoutValueList = getCheckoutValueList(checkoutValues, t);

    return (
        <div className="flex flex-col items-center justify-center px-8 py-10 surface on-surface-text">
            <div className="rich-text-block prose-default max-width">
                {checkoutValueList.length > 0 ? (
                    <>
                        <h2 className="text-heading-3 font-medium margin-2">{t('checkout_values.header')}</h2>
                        <ul>
                            {checkoutValueList.map((checkoutValue, index) => (
                                <li key={index}>
                                    <p className="font-body font-serif margin-6 peer-margin-collapse">{checkoutValue}</p>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <span className="text-heading-3 font-medium margin-2">{t('checkout_values.no_options')}</span>
                )}
            </div>
        </div>
    )
}

export default CheckoutValuesDisplay;


function getCheckoutValueList(orderString, t) {
    const orderDetails = parseOrderDetails(orderString);

    const getMappedValue = (key, value) => {
        const getKeyClosestToValue = (value) => {
            const keys = [20, 40, 60, 80, 100];
            return keys.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
        }
        const closestKey = getKeyClosestToValue(value);
        return t(`user_grid.category.${key}.${closestKey}`)
    }

    const formatValue = (key, value) => {
        if (Array.isArray(value)) {
            const translatedValues = value.map(individualValue =>  t(`user_grid.category.${key}.${individualValue}`));
            return `'${translatedValues.join(', ')}'`;
        } else if (key === 'PronunciationEase' || key === 'Commonality') {
            return `'${getMappedValue(key, value)}'`;
        } else if (key === 'Syllables' || key === 'Starts' || key === 'Ends') {
            return `'${value}'`;
        }
        return `'${t(`user_grid.category.${key}.${value}`)}'`;
    };

    const parts = [];
    for (const key in orderDetails) {
        if (orderDetails.hasOwnProperty(key)) {
            const value = orderDetails[key];
            const formattedValue = formatValue(key, value);
            const formattedKey = t(`user_grid.category.${key}.label`)
            parts.push(`${formattedKey}: ${formattedValue}`);
        }
    }

    return parts;
}

function parseOrderDetails(inputString) {
    if (!inputString) { // Check if inputString is empty or undefined
        return {};
    }
    const pairs = inputString.split(';;');
    const result = {};

    pairs.forEach(pair => {
        const [key, value] = pair.split('=');
        if (result.hasOwnProperty(key)) {
            // If the key already exists, convert its value to an array and append the new value
            if (!Array.isArray(result[key])) {
                result[key] = [result[key]];
            }
            result[key].push(value);
        } else {
            result[key] = value;
        }
    });
    return result;
}