import React, { useEffect, useRef } from 'react';

function SegmentedButtonNoSelect({
  segments,
  selectedValue,
}) {

  const controlRef = useRef();

  useEffect(() => {
    const activeSegment = segments.find(seg => seg.value === selectedValue);
    if (!activeSegment) return;

    const activeSegmentRef = activeSegment.ref;
    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;

    style.setProperty("--highlight-width", `${offsetWidth}px`);
    style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
  }, [selectedValue, segments]);

  return (
    <div className="segments-container" ref={controlRef}>
      <div className="segments no-select-segments">
        {segments.map((item) => (
          <div
            key={item.value}
            className={`segment ${item.value === selectedValue ? "active" : "inactive"}`}
            ref={item.ref}
          >
            <span className="label text-sm font-serif">{item.label}</span>
            <span className="secondaryLabel text-sm font-serif">{item.secondaryLabel}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SegmentedButtonNoSelect;

