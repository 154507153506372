import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import BuyButton from './BuyButton';
import Footer from './Footer';
import Header from './Header';
import SegmentedButton from './SegmentedButton';
import SegmentedButtonNoSelect from './SegmentedButtonNoSelect'
import UserSelectionGrid from './UserSelectionGrid';
import UserSelectionDropdown from './UserSelectionDropdown';
import UserSelectionSlider from './UserSelectionSlider';
import UserSelectionQualityGrid from './UserSelectionQualityGrid';
import { useTranslation } from 'react-i18next';

function PageMain({
  checkboxStates,
  handleCheckboxChange,
  userValues,
  handleValueChange,
  timeframe,
  handleTimeframeChange,
  optionsNum,
  optionsVal
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const startCheckout = () => {
    navigate(`/${i18n.language}/checkout`);
  }


  return (
    <div>
      <Header 
        contentText={t('hero.tagline')}
      />
      <div className="flex flex-col items-center justify-center px-8 py-10 surface-variant on-surface-variant-text">
        <div className="rich-text-block prose-default max-width w-full">
          <h2 className="text-heading-3 font-medium margin-2">{t('hero.title')}</h2>
          <p className="font-body font-serif margin-6 peer-margin-collapse">{t('hero.content1')}</p>
          <p className="font-body font-serif margin-6 peer-margin-collapse">{t('hero.content2')}</p>
          <p className="font-body font-serif margin-6 peer-margin-collapse">{t('hero.content3')}</p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center px-8 py-10 surface on-surface-text">
        <div className="rich-text-block prose-default max-width w-full">
          <h2 className="text-heading-3 font-medium margin-2">{t('apart.title')}</h2>
          <ul className="font-body">
            <li>
              <b className="font-bold margin-6 peer-margin-collapse">{t('apart.content1.header')}</b>
              <div className="font-serif margin-6 peer-margin-collapse">{t('apart.content1.text')}</div>
            </li>
            <li>
              <b className="font-bold margin-6 peer-margin-collapse">{t('apart.content2.header')}</b>
              <div className="font-serif margin-6 peer-margin-collapse">{t('apart.content2.text')}</div>
            </li>
            <li>
              <b className="font-bold margin-6 peer-margin-collapse">{t('apart.content3.header')}</b>
              <div className="font-serif margin-6 peer-margin-collapse">{t('apart.content3.text')}</div>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center px-8 py-10 surface-variant on-surface-variant-text">
        <div className="rich-text-block prose-default max-width w-full">
          <h2 className="text-heading-3 font-medium margin-2">{t('how_works.title')}</h2>
          <ol type="1" className="font-body">
            <li>
              <b className="font-bold margin-6 peer-margin-collapse">{t('how_works.content1.header')}</b>
              <div className="font-serif margin-6 peer-margin-collapse">{t('how_works.content1.text')}</div>
            </li>
            <li>
              <b className="font-bold margin-6 peer-margin-collapse">{t('how_works.content2.header')}</b>
              <div className="font-serif margin-6 peer-margin-collapse">{t('how_works.content2.text')}</div>
            </li>
            <li>
              <b className="font-bold margin-6 peer-margin-collapse">{t('how_works.content3.header')}</b>
              <div className="font-serif margin-6 peer-margin-collapse">{t('how_works.content3.text')}</div>
            </li>
            <li>
              <b className="font-bold margin-6 peer-margin-collapse">{t('how_works.content4.header')}</b>
              <div className="font-serif margin-6 peer-margin-collapse">{t('how_works.content4.text')}</div>
            </li>
          </ol>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center px-8 py-10 surface on-surface-text rich-text-block prose-default">
        <h2 className="text-heading-3 font-medium margin-6">{t('user_grid.title')}</h2>
        <UserSelectionGrid>
          <UserSelectionDropdown
            label={t('user_grid.category.Gender.label')}
            name="Gender"
            checked={checkboxStates["Gender"] || false}
            selected={userValues["Gender"] || "Female"}
            displayItems={[t('user_grid.category.Gender.Male'), t('user_grid.category.Gender.Female'), t('user_grid.category.Gender.Neutral')]}
            englishItems={["Male", "Female", "Neutral"]}
            handleCheckboxChange={handleCheckboxChange}
            handleValueChange={handleValueChange}
          />
          <UserSelectionDropdown
            label={t('user_grid.category.Themes.label')}
            name="Themes"
            checked={checkboxStates["Themes"] || false}
            selected={userValues["Themes"] || "animals"}
            displayItems={[t('user_grid.category.Themes.animals'), t('user_grid.category.Themes.art'), t('user_grid.category.Themes.astronomy'), t('user_grid.category.Themes.colors'), t('user_grid.category.Themes.Disney'), t('user_grid.category.Themes.food'), t('user_grid.category.Themes.geography'), t('user_grid.category.Themes.history'), t('user_grid.category.Themes.intellectuals'), t('user_grid.category.Themes.literature'), t('user_grid.category.Themes.marine'), t('user_grid.category.Themes.movies'), t('user_grid.category.Themes.music'), t('user_grid.category.Themes.mythology'), t('user_grid.category.Themes.nature'), t('user_grid.category.Themes.politics'), t('user_grid.category.Themes.religion'), t('user_grid.category.Themes.royalty'), t('user_grid.category.Themes.science'), t('user_grid.category.Themes.theater'), t('user_grid.category.Themes.sports'), t('user_grid.category.Themes.television'), t('user_grid.category.Themes.virtues')]}
            englishItems={["animals", "art", "astronomy", "colors", "Disney", "food", "geography", "history", "intellectuals", "literature", "marine", "movies", "music", "mythology", "nature", "politics", "religion", "royalty", "science", "theater", "sports", "television", "virtues"]}
            handleCheckboxChange={handleCheckboxChange}
            handleValueChange={handleValueChange}
          />
          <UserSelectionDropdown
            label={t('user_grid.category.Syllables.label')}
            name="Syllables"
            checked={checkboxStates["Syllables"] || false}
            selected={userValues["Syllables"] || "1"}
            displayItems={["1", "2", "3", "4"]}
            englishItems={["1", "2", "3", "4"]}
            handleCheckboxChange={handleCheckboxChange}
            handleValueChange={handleValueChange}
          />
          <UserSelectionDropdown
            label={t('user_grid.category.Religion.label')}
            name="Religion"
            checked={checkboxStates["Religion"] || false}
            selected={userValues["Religion"] || "Buddhist"}
            displayItems={[t('user_grid.category.Religion.Buddhist'), t('user_grid.category.Religion.Christian'), t('user_grid.category.Religion.Hindu'), t('user_grid.category.Religion.Jewish'), t('user_grid.category.Religion.Islamic')]}
            englishItems={["Buddhist", "Christian", "Hindu", "Jewish", "Islamic"]}
            handleCheckboxChange={handleCheckboxChange}
            handleValueChange={handleValueChange}
          />
          <UserSelectionDropdown
            label={t('user_grid.category.Starts.label')}
            name="Starts"
            checked={checkboxStates["Starts"] || false}
            selected={userValues["Starts"] || "A"}
            displayItems={["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]}
            englishItems={["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]}
            handleCheckboxChange={handleCheckboxChange}
            handleValueChange={handleValueChange}
          />
          <UserSelectionDropdown
            label={t('user_grid.category.Ends.label')}
            name="Ends"
            checked={checkboxStates["Ends"] || false}
            selected={userValues["Ends"] || "A"}
            displayItems={["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]}
            englishItems={["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]}
            handleCheckboxChange={handleCheckboxChange}
            handleValueChange={handleValueChange}
          />
          <UserSelectionSlider
            label={t('user_grid.category.PronunciationEase.label')}
            name="PronunciationEase"
            checked={checkboxStates["PronunciationEase"] || false}
            value={userValues["PronunciationEase"] || "50"}
            minLabel="Sue"
            maxLabel="Bartholomew"
            handleCheckboxChange={handleCheckboxChange}
            handleValueChange={handleValueChange}
          />
          <UserSelectionSlider
            label={t('user_grid.category.Commonality.label')}
            name="Commonality"
            checked={checkboxStates["Commonality"] || false}
            value={userValues["Commonality"] || "50"}
            minLabel={t('user_grid.category.Commonality.Rare')}
            maxLabel={t('user_grid.category.Commonality.Common')}
            handleCheckboxChange={handleCheckboxChange}
            handleValueChange={handleValueChange}
          />
          <UserSelectionQualityGrid
            checked={checkboxStates["Qualities"] || false}
            handleCheckboxChange={handleCheckboxChange}
            handleValueChange={handleValueChange}
            inValues={userValues["Qualities"] || ""}
          />
        </UserSelectionGrid>
        <div className="purchase-grid w-full max-width">

          <div className="flex flex-col items-center rich-text-block prose-default max-width w-full purchase-item py-10">
            <p className="text-heading-4 font-medium on-background-text margin-2 peer-margin-collapse">{t('response.title')}</p>
            <p className="text-sm font-serif on-background-text margin-2 peer-margin-collapse">{t('response.content1')}</p>
            <p className="text-sm font-serif on-background-text margin-2 peer-margin-collapse">{t('response.content2')}</p>
            <SegmentedButton
              name="timeframe"
              callback={handleTimeframeChange}
              selectedValue={timeframe}
              segments={[
                {
                  label: t('response.timeframe7'),
                  secondaryLabel: "+ $0",
                  value: "7",
                  ref: useRef()
                },
                {
                  label: t('response.timeframe3'),
                  secondaryLabel: "+ $2",
                  value: "3",
                  ref: useRef()
                },
                {
                  label: t('response.timeframe1'),
                  secondaryLabel: "+ $5",
                  value: "1",
                  ref: useRef()
                }
              ]}
            />
          </div>

          <div className="flex flex-col items-center rich-text-block prose-default max-width w-full purchase-item">
            <span className="text-heading-4 font-medium on-background-text margin-2 peer-margin-collapse">{t('options.title')}</span>
            <span className="text-sm font-serif on-background-text margin-2 peer-margin-collapse">{t('options.content1')}</span>
            <SegmentedButtonNoSelect
              selectedValue={optionsVal}
              segments={[
                {
                  label: t('options.0-3'),
                  secondaryLabel: "+ $0",
                  value: "3",
                  ref: useRef()
                },
                {
                  label: t('options.4-5'),
                  secondaryLabel: "+ $2",
                  value: "5",
                  ref: useRef()
                },
                {
                  label: t('options.6+'),
                  secondaryLabel: "+ $7",
                  value: "X",
                  ref: useRef()
                }
              ]}
            />
          </div>
          <div className="flex flex-col items-center w-full purchase-item py-10">
            <BuyButton
              timeframe={timeframe}
              optionsNum={optionsNum}
              startCheckout={startCheckout}
            />
          </div>
        </div>
      </div>
      <Footer
        variant={true}
      />
    </div>
  );
}

export default PageMain