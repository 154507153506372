import React, { useCallback, useMemo, useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { NavigationType, useNavigationType, useNavigate } from "react-router-dom";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import CheckoutValuesDisplay from "./CheckoutValuesDisplay";
import Header from "./Header";
import Footer from "./Footer";
import SimpleButton from './SimpleButton'
import Spinner from './Spinner';
import priceIds from '../config/priceIds';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY);

function PageCheckout({ inputValues }) {
    const { t, i18n } = useTranslation();
    const { checkoutValues, timeframe, optionsNum } = inputValues;

    const [canCheckout, setCanCheckout] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [retryCount, setRetryCount] = useState(0);

    const optionsVal = useMemo(() => getOptionsValue(optionsNum), [optionsNum]);
    const priceKey = `days${timeframe}choices${optionsVal}`;
    const priceId = priceIds[priceKey];

    const navType = useNavigationType();
    const navigate = useNavigate();

    useEffect(() => {
        if (navType === NavigationType.Pop) {
            navigate(`/${i18n.language}/`);
            return;
        }
        setCanCheckout(true);
      }, [navType, navigate, i18n.language])

    const fetchClientSecret = useCallback(async () => {
        setIsLoading(true);
        const apiDomain = process.env.REACT_APP_API_DOMAIN || `http://localhost:3000`
        const function_key = process.env.REACT_APP_FUNC_DEFAULT_KEY;
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-functions-key': function_key
        };
        const body = JSON.stringify({ priceId, checkoutValues, timeframe, optionsNum, locale: `${i18n.language}` });
        try {
            const res = await fetch(`${apiDomain}/api/initiateCheckout`, {
                method: 'POST',
                headers: headers,
                body: body
            });
            if (res.status !== 200) {
                setIsLoading(false);
                throw new Error(t('error.checkout_initiate_failed'));
            }

            const data = await res.json();
            setError(null);
            setIsLoading(false);
            return data.clientSecret;
        } catch (err) {
            setError(err.message || t('unexpected'));
            setIsLoading(false);
            return null;
        }

    }, [priceId, checkoutValues, timeframe, optionsNum, t, i18n]);

    const retry = () => {
        setError(null); // Clear the error state
        setRetryCount((prev) => prev + 1);
    };

    return (
        <div id="checkout" className="flex flex-col h-screen background on-background-text">
            <Header/>
            {error && !isLoading ? (
                <div className="flex flex-col items-center">
                    <div className="error on-error-text px-8 py-10 margin-6">
                        <h2 className="text-heading-3 font-medium margin-2">{error}</h2>
                    </div>
                    <SimpleButton
                        clickButton={retry}
                        text={t('simple.Retry')}
                    />
                </div>
            ) : canCheckout ? (
                <>
                    <EmbeddedCheckoutProvider
                        key={retryCount}
                        stripe={stripePromise}
                        options={{ fetchClientSecret }}
                    >
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                    <CheckoutValuesDisplay
                        checkoutValues={checkoutValues}
                    />
                </>
            ) : (
                <div id="checkout" className="flex flex-col items-center margin-6 background on-background-text">
                    <Spinner />
                </div>
            )}
            <Footer variant={true}/>
        </div>
    )
}

export default PageCheckout;

function getOptionsValue(optionsNum) {
    if (optionsNum >= 6) {
        return 'X';
    } else if (optionsNum >= 4) {
        return '5';
    } else {
        return '3';
    }
}