const priceIds = {
  "days1choices3": "price_1P3YKMRtZcmnq7xy9xEXsIPX",
  "days1choices5": "price_1P3YMNRtZcmnq7xyGGR1i7At",
  "days1choicesX": "price_1P3YNGRtZcmnq7xy7b69ZNiF",
  "days3choices3": "price_1P3WhtRtZcmnq7xy0jd95pLi",
  "days3choices5": "price_1P3YM3RtZcmnq7xyiznnL3o5",
  "days3choicesX": "price_1P3YMxRtZcmnq7xyr7IE6T9Y",
  "days7choices3": "price_1P3YIHRtZcmnq7xyQS6ovFhr",
  "days7choices5": "price_1P3YLpRtZcmnq7xyY3GYbJFd",
  "days7choicesX": "price_1P3YMjRtZcmnq7xyPgzqHBaV",
};

export default priceIds
