import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import CheckoutValuesDisplay from "./CheckoutValuesDisplay";
import Spinner from './Spinner';
import SimpleButton from './SimpleButton'
import { useTranslation, Trans } from 'react-i18next';
import Header from './Header';
import Footer from './Footer';

const Status = Object.freeze({
    //Our types when Stripe hasn't given us one
    loading: 'loading',
    no_id: 'no_id',
    error: 'error',
    //Stripe types:
    complete: 'complete', //The checkout session is complete. Payment processing may still be in progress
    open: 'open', //The checkout session is still in progress. Payment processing has not started
    expired: 'expired' //The checkout session has expired. No further processing will occur

})

function PageReturn() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [status, setStatus] = useState(Status.loading);
    const [orderDetails, setOrderDetails] = useState(null);
    const [customerEmail, setCustomerEmail] = useState(null);

    useEffect(() => {
        const apiDomain = process.env.REACT_APP_API_DOMAIN || 'http://localhost:7071'
        const insertOrderTarget = 'insertOrder';
        const checkOrderTarget = 'checkOrder';
        const sessionStatusTarget = 'session-status';
        const function_key = process.env.REACT_APP_FUNC_DEFAULT_KEY;
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-functions-key': function_key
        };

        const insertOrderInDB = async (order) => {
            fetch(`${apiDomain}/api/${insertOrderTarget}`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(order)
            });
        };

        const fetchSessionStatus = async () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const session_id = urlParams.get('session_id');

            if (!session_id) {
                setStatus(Status.no_id)
                return;
            }

            const body = JSON.stringify({ session_id });

            try {
                const orderCheck = await fetch(`${apiDomain}/api/${checkOrderTarget}?id=${session_id}`, {
                    method: 'GET',
                    headers: headers,
                });
                const orderCheckData = await orderCheck.json();
                if (orderCheckData.length === 0) {
                    const session = await fetch(`${apiDomain}/api/${sessionStatusTarget}`, {
                        method: 'POST',
                        headers: headers,
                        body: body
                    });
                    const sessionData = await session.json();
                    if (session.status !== 200) {
                        throw new Error(`HTTP error! Status: ${session.status}`)
                    }
                    if (sessionData.PaymentStatus === 'complete') {
                        setOrderDetails(sessionData.OrderDetails);
                        setCustomerEmail(sessionData.CustomerEmail);
                        insertOrderInDB(sessionData);
                    };
                    setStatus(sessionData.PaymentStatus);
                } else {
                    setOrderDetails(orderCheckData[0].OrderDetails);
                    setCustomerEmail(orderCheckData[0].CustomerEmail);
                    setStatus(Status.complete);
                }
            } catch (error) {
                setStatus(Status.error);
                console.error("Failed to fetch session status:", error);
            }
        };
        fetchSessionStatus();
    }, []);

    const returnToMain = () => {
        navigate(`/${i18n.language}/`);
    };

    switch (status) {
        case Status.loading:
            return (
                <div id="loading" className="flex flex-col h-screen background on-background-text margin-6">
                    <Header />
                    <div id="checkout" className="flex flex-col items-center margin-6 background on-background-text">
                        <Spinner />
                    </div>
                    <Footer variant={true} />
                </div>
            );
        case Status.complete:
            return (
                <div id="loading" className="flex flex-col h-screen background on-background-text margin-6">
                    <Header />
                    <div id="checkout" className="flex flex-col items-center margin-6 background on-background-text">

                    <p className="text-heading-3 font-medium">{t('return.header')}</p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">{t('return.confirmation_email', { customerEmail: customerEmail })}</p>
                    <CheckoutValuesDisplay
                        checkoutValues={orderDetails}
                    />
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <Trans i18nKey="return.email_us">
                            If you have any questions, please email us at: <a href="mailto:orders@english-names.com" className="on-background-text custom-link">orders@english-names.com</a>.
                        </Trans>
                    </p>
                    </div>
                    <Footer variant={true} />
                </div>
            );
        case Status.open:    //Payment failed or was canceled.
        case Status.expired: //Checkout incomplete, session expired.
            return (
                <Navigate to={`/${i18n.language}/checkout`} />
            );
        case Status.no_id:
        case Status.error:
        default:
            return (
                <div id="loading" className="flex flex-col h-screen background on-background-text">
                    <Header />
                    <div id="checkout" className="flex flex-col items-center margin-6 background on-background-text">
                        <p className="text-heading-3 font-medium on-background-text">{t('error.unexpected')}</p>
                        <SimpleButton
                            clickButton={returnToMain}
                            text={t('simple.Back')}
                        />
                    </div>
                    <Footer variant={true} />
                </div>
            );
    }
}

export default PageReturn;