const priceCosts = {
  "days1choices3": "$8",
  "days1choices5": "$10",  
  "days1choicesX": "$15",
  "days3choices3": "$5",
  "days3choices5": "$7",  
  "days3choicesX": "$12",
  "days7choices3": "$3",
  "days7choices5": "$5",  
  "days7choicesX": "$10",
};

export default priceCosts
