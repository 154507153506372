import React, { useEffect, useState } from 'react';
import LogoDark from '../images/LogoDark.png';
import LogoLight from '../images/LogoLight.png';
import { useTranslation } from 'react-i18next';

function Logo() {
    const { t } = useTranslation();
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');

        // Initial check
        setIsDarkMode(matchMedia.matches);

        // Listener for changes
        const handleChange = (e) => {
            setIsDarkMode(e.matches);
        };

        matchMedia.addEventListener('change', handleChange);

        // Cleanup listener on unmount
        return () => {
            matchMedia.removeEventListener('change', handleChange);
        };
    }, []);

    return (
        <div className="logo">
            <img src={isDarkMode ? LogoDark : LogoLight} alt={t('logo_alt')}/>
        </div>
    );
};

export default Logo