import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useTranslation, Trans } from 'react-i18next';

function PageTerms() {
    const { t } = useTranslation();
    return (
        <div>
            <Header />
            <div className="flex flex-col items-center justify-center px-8 py-10 surface-variant on-surface-variant-text">
                <div className="rich-text-block prose-default max-width w-full">
                    <h1 className="text-heading-3 font-medium margin-6">{t('terms.title')}</h1>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">{t('terms.intro.update_label')}</b>
                        : {t('terms.intro.update_text')}
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">{t('terms.intro.content1')}</p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">{t('terms.intro.content2')}</p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">{t('terms.services.label')}</b>
                        {t('terms.services.content1')}
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">{t('terms.payment.label')}</b>
                        <ul className="font-body">
                            <li>
                                <div className="font-serif margin-6 peer-margin-collapse">{t('terms.payment.content1')}</div>
                            </li>
                            <li>
                                <div className="font-serif margin-6 peer-margin-collapse">{t('terms.payment.content2')}</div>
                            </li>
                            <li>
                                <Trans i18nKey="terms.payment.content3">
                                   For any issues, please email us at <a href="mailto:orders@english-names.com" className="on-surface-variant-text custom-link">orders@english-names.com</a>.
                                </Trans>
                            </li>
                        </ul>
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">{t('terms.intellectual.label')}</b>
                        {t('terms.intellectual.content1')} {t('terms.intellectual.content2')}
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">{t('terms.prohibited.label')}</b>
                        {t('terms.prohibited.content1')}
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">{t('terms.liability.label')}</b>
                        {t('terms.liability.content1')} {t('terms.liability.content2')} 
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        <b class="font-bold">{t('terms.miscellaneous.label')}</b>
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        {t('terms.governing.label')}{t('terms.governing.content1')}
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        {t('terms.privacy.label')}
                        <Trans i18nKey="terms.privacy.content1">
                            Please refer to our <a href="/privacy" className="on-surface-variant-text custom-link margin-6 font-serif">Privacy Policy</a> for details on how we handle user information.
                        </Trans>
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        {t('terms.termination.label')}{t('terms.termination.content1')}
                    </p>
                    <p className="font-body font-serif margin-6 peer-margin-collapse">
                        {t('terms.contact.label')}
                        <Trans i18nKey="terms.contact.content1">
                                   You may contact us by emailing us at <a href="mailto:contact@english-names.com" className="on-surface-variant-text custom-link">contact@english-names.com</a>.
                        </Trans>
                    </p>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default PageTerms