import React, { useRef, useEffect } from 'react';
import '@material/web/checkbox/checkbox.js';
import '@material/web/button/filled-button.js';
import '@material/web/menu/menu.js';
import '@material/web/menu/menu-item.js';
import '@material/web/textfield/outlined-text-field.js';
import '@material/web/icon/icon.js';
import TrackedCheckbox from './TrackedCheckbox';

function UserSelectionDropdown({ label, name, checked, selected, displayItems, englishItems, handleCheckboxChange, handleValueChange }) {

  const menuRef = useRef(null);
  const rowRef = useRef(null);

  // Attach the click event listener to the button
  useEffect(() => {
    const anchorEl = document.body.querySelector(`#${name}-anchor`);
    const menuEl = menuRef.current;

    const handleClick = (event) => {
      event.stopPropagation();
      menuEl.open = !menuEl.open;
    };

    anchorEl.addEventListener('click', handleClick);

    return () => {
      anchorEl.removeEventListener('click', handleClick);
    };
  }, [name]);

  // Update selected option when a menu item is clicked
  const handleMenuItemClick = (option) => {
    handleValueChange(name, option);
  };

  // Notify parent when checked state changes
  const handleCheckedChange = (isChecked) => {
    handleCheckboxChange(name, isChecked);
    handleValueChange(name, selected);
  };

  const handleRowClick = () => {
    const checkbox = rowRef.current.querySelector('md-checkbox');
    if (checkbox) {
      checkbox.click(); // Simulate a click on the checkbox.
    }
  };

  return (
    <div className="single-column dropdown-holder margin-2" ref={rowRef} onClick={handleRowClick}>
      <div className="dropdown-left">
        <TrackedCheckbox name={name} checked={checked} onCheckedChange={handleCheckedChange} />
        <span className="font-body font-serif">{label}:</span>
      </div>
      <div className="dropdown-right" style={{ position: "relative" }}>
          <md-outlined-text-field
            id={`${name}-anchor`}
            value={displayItems[englishItems.indexOf(selected)]}
            readOnly="true"
            hasTrailingIcon="true"
            class="text-field-long"
          >
            <md-icon slot="trailing-icon">expand_more</md-icon>
          </md-outlined-text-field>

          <md-menu class="background" ref={menuRef} anchor={`${name}-anchor`}>
            {englishItems.map((option, index) => (
              <md-menu-item
                class="background"
                key={index}
                onClick={(event) => {
                  event.stopPropagation()
                  handleMenuItemClick(option)
                }
                }
              >
                <div slot="headline">{displayItems[index]}</div>
              </md-menu-item>
            ))}
          </md-menu>
      </div>
    </div>
  );
}

export default UserSelectionDropdown;