import React, { useEffect, useRef } from 'react';
import '@material/web/chips/filter-chip.js';

function UserSelectionQualityChip({ quality, display, enabled, onSelectedChange, selected }) {
  const chipRef = useRef(null);

  useEffect(() => {
    const chip = chipRef.current;
    if (chip) {
      const handleChange = () => {
        onSelectedChange(chip.selected);  // Notify parent component
      };

      chip.addEventListener('click', handleChange);
      return () => {
        chip.removeEventListener('click', handleChange);
      };
    }
  }, [onSelectedChange]);

  useEffect(() => {
    const chip = chipRef.current;
    if (chip) {
      chip.selected = selected;
    }
  }, [selected]);

  return (
      <md-filter-chip
        key={quality}
        ref={chipRef}
        label={display}
        disabled={enabled ? undefined : true}
        selected={selected}>
      </md-filter-chip>
  );
}

export default UserSelectionQualityChip;

